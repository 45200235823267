import React from "react";
import * as Icon from "react-feather";
import { CgProductHunt } from "react-icons/cg";

const navigationConfig = [
  {
    id: "dashboard",
    title: "Dashboard",
    type: "item",
    icon: <Icon.Layout size={20} />,
    navLink: "/",
    // badge: "warning",
    // badgeText: "2",
  },
  //! -----------Home Page---------
  {
    id: "homePage",
    title: "Home",
    type: "item",
    icon: <Icon.Home size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/home-page",
  },

  //!---------------- Add Property--------
  {
    id: "addPropertyList",
    title: "Properties",
    type: "item",
    icon: <Icon.Package size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/property/list",
  },
  //!---------------- Add developer--------
  {
    id: "adddeveloperList",
    title: "Developers",
    type: "item",
    icon: <Icon.Users size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/developer/list",
  },
  //!---------------- our inventory--------
  {
    id: "inventoryList",
    title: "Our Inventory",
    type: "item",
    icon: <Icon.Package size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/inventory/list",
  },

  //!---------------- Add locations--------
  {
    id: "locationsList",
    title: "Locations",
    type: "item",
    icon: <Icon.MapPin size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/locations/list",
  },

  //!---------------- Teams Sort --------
  {
    id: "ourteam",
    title: "Our Team",
    type: "collapse",
    icon: <Icon.List size={15} />,
    children: [
      {
        id: "teamsList",
        title: "Our Team",
        type: "item",
        icon: <Icon.Users size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/team/list",
      },
      {
        id: "teamsSort",
        title: "Our Team Sort",
        type: "item",
        icon: <Icon.Users size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/team/sort",
      },
    ]
  },

  // !---------------- Media--------
  {
    id: "medialist",
    title: "Media",
    type: "item",
    icon: <Icon.Video size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/media/list",
  },

  //!----------------Event--------
  {
    id: "eventList",
    title: "Events",
    type: "item",
    icon: <Icon.Calendar size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/event/list",
  },

  //!----------------speaker--------
  {
    id: "speakerList",
    title: "Our Speakers",
    type: "item",
    icon: <Icon.Users size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/speaker/list",
  },

  //!----------------partners--------
  {
    id: "partnersList",
    title: "Our Partners",
    type: "item",
    icon: <Icon.Users size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/partners/list",
  },

  //!----------------Blog--------
  {
    id: "blogList",
    title: "Blog",
    type: "item",
    icon: <Icon.AlertTriangle size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/blog/list",
  },

  //!---------------- career --------
  {
    id: "careerList",
    title: "Career",
    type: "item",
    icon: <Icon.PlayCircle size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/career/list",
  },

  //!--------------Videos-------
  // {
  //   id: "videosForm",
  //   title: "Videos",
  //   type: "item",
  //   icon: <Icon.Video size={12} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/videos/list",
  // },

  //!--------------Pages-------
  {
    id: "pages",
    title: "Pages",
    type: "item",
    icon: <Icon.Paperclip size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/pages",
  },
  {
    id: "staticPages",
    title: "Static Pages",
    type: "collapse",
    icon: <Icon.Anchor size={15} />,
    children: [
      {
        id: "about-us",
        title: "About Us",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/about-us/form",
      },
      {
        id: "developer",
        title: "Developer",
        type: "item",
        icon: <Icon.Package size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/developer",
      },
      {
        id: "Team",
        title: "Our Team",
        type: "item",
        icon: <Icon.Users size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/team",
      },
      {
        id: "inventory",
        title: "Inventory",
        type: "item",
        icon: <Icon.Package size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/inventory",
      },
      {
        id: "properties",
        title: "Properties",
        type: "item",
        icon: <Icon.Package size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/properties",
      },
      {
        id: "media",
        title: "Media",
        type: "item",
        icon: <Icon.Video size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/media",
      },
      {
        id: "event",
        title: "Events",
        type: "item",
        icon: <Icon.Calendar size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/event",
      },
      {
        id: "contact",
        title: "Contact",
        type: "item",
        icon: <Icon.Phone size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/contact",
      },
      // {
      //   id: "faq-page",
      //   title: "FAQ's",
      //   type: "item",
      //   icon: <Icon.HelpCircle size={12} />,
      //   permissions: ["admin", "editor"],
      //   navLink: "/faq",
      // },
      {
        id: "career",
        title: "Career",
        type: "item",
        icon: <Icon.PlayCircle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/career",
      },
      {
        id: "agencypartner",
        title: "Agency Partners",
        type: "item",
        icon: <Icon.Users size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/agency-partner",
      },
      {
        id: "blog",
        title: "Blog",
        type: "item",
        icon: <Icon.AlertTriangle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/blog",
      },
      // {
      //   id: "agent",
      //   title: "Real Estate Agent",
      //   type: "item",
      //   icon: <Icon.PlayCircle size={12} />,
      //   permissions: ["admin", "editor"],
      //   navLink: "/agent",
      // },
      // {
      //   id: "news-page",
      //   title: "News",
      //   type: "item",
      //   icon: <Icon.PlayCircle size={12} />,
      //   permissions: ["admin", "editor"],
      //   navLink: "/news",
      // },
      {
        id: "privacyPolicy",
        title: "Privacy Policy",
        type: "item",
        icon: <Icon.FileText size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/privacy-policy",
      },
      {
        id: "termsfUse",
        title: "Terms & Conditions",
        type: "item",
        icon: <Icon.FileText size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/terms-of-service",
      },
      // {
      //   id: "testimonial",
      //   title: "Testimonials",
      //   type: "item",
      //   icon: <Icon.Clipboard size={12} />,
      //   permissions: ["admin", "editor"],
      //   navLink: "/testimonial",
      // },
    ],
  },

  {
    id: "questionAndAnswersForm",
    title: "FAQ",
    type: "item",
    icon: <Icon.HelpCircle size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/faqs/form",
  },

  // //!---------------Users------
  // {
  //   id: "users",
  //   title: "Registered Users",
  //   type: "item",
  //   icon: <Icon.User size={12} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/users-list",
  // },

  // //!---------------Reviews------
  // {
  //   id: "reviews",
  //   title: "Reviews",
  //   type: "item",
  //   icon: <Icon.Star size={12} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/reviews",
  // },
  // //!---------------Header------
  // {
  //   id: "header",
  //   title: "Header",
  //   type: "item",
  //   icon: <Icon.Flag size={12} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/header",
  // },
  // //!---------------Footer------
  // {
  //   id: "footer",
  //   title: "Footer",
  //   type: "item",
  //   icon: <Icon.CheckSquare size={12} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/footer",
  // },

  //!---------------FormsDropDown------
  {
    id: "FormsDropDown",
    title: "Filter Queries",
    type: "item",
    icon: <Icon.ShoppingBag size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/filter-list",
  },
  // {
  //   id: "Investment",
  //   title: "Investment",
  //   type: "item",
  //   icon: <Icon.User size={12} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/investment-list",
  // },
  // {
  //   id: "Agents",
  //   title: "Agent Requests",
  //   type: "item",
  //   icon: <Icon.User size={12} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/agents-list",
  // },
  //!-------------Gallery--------
  {
    id: "gallery",
    title: "Gallery",
    type: "item",
    icon: <Icon.Image size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/gallery",
  },
  //!------------Static Pages--------
];

export default navigationConfig;
